.menu-nodal-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #5B5B5B;
    z-index: 100;
}
.menu-modal-item {
    height: 18vh;
    display: flex;
    align-items: center;
    font-size: 10vh;
    padding: 0 0 0 1vw;
    transition: all 0.3s ease-out;
    color: #ECE4D8;
}
.menu-modal-item:hover {
    color: black;
    background-color: #ECE4D8;
    cursor: pointer;
    box-shadow: 0px 15px 15px black, 0px -15px 15px black;
}
.menu-nodal-container > a {
    text-decoration: none;
}
