.separator-container {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.separator-object {
    width: 70%;
    background-color: rgb(121, 121, 121);
    height: 4px;
    border-radius: 3px;
}