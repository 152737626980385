.summary-container {
    height: fit-content;
    width: 60vw;
    padding: 10px;
    background-color: #ECE4D8;
    font-size: x-large;
    overflow: auto;
    display: grid;
    grid-template-columns: 6fr 2fr;
    grid-template-rows: 2fr 1fr;
    grid-template-areas: 
        "t i"
        "s s";
    gap: 30px;
}
.summary-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
.summary-text-inner {
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
}
.summary-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

}
.avatar {
    height: 30vh;
}
.summary-skills {
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    grid-area: s; 
    gap: 10px
}
.summary-skills-category {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.summary-skills-category > img {
    height: 4vh;
    border-radius: 5px;
    transition: all 0.3s ease-out;
}
.summary-skills-category > img:hover {
    box-shadow: 0px 5px 10px #5B5B5B;
}