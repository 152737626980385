.header-container {
    height: 7vh;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.5vh;
    gap: 1.5vh;
    position: fixed;
    transition: all .3s ease;
    z-index: 50;
}
.header-container.header_hidden {
	transform: translateY(-100%);
}
.header-container > a {
    text-decoration: none;
}
.header-item {
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: center;
    padding: 0 0.5vh;
    font-size:x-large;
    background-color: #ECE4D8;
    gap: 2vh;
    color: black;
    transition: all 0.3s ease-out;
    /* border: 1px solid #4D4D4D; */
    box-shadow: 7px 7px 10px #5B5B5B;
}
.header-item:hover {
    background-color: black;
    color: #ECE4D8;
    cursor: pointer;
    box-shadow: 15px 15px 15px #ECE4D8;
}